@font-face {
    font-family: 'INTER BOLD';
    src:  url('../assets/fonts/Inter-Bold.ttf'),
}

@font-face {
    font-family: 'INTER MEDIUM';
    src: url('../assets/fonts/Inter-Medium.ttf'),
}

@font-face {
    font-family: 'INTER REGULAR';
    src: url('../assets/fonts/Inter-Regular.ttf'),

}

//Fonts
$boldfont: 'INTER BOLD';
$mediumfont: 'INTER MEDIUM';
$regularfont: 'INTER REGULAR';


//colors

$mainColor: #F3970C;
$secondaryColor: #008B40;
$menuColor: #FFC737;
$white: #FFFFFF;
$black: #000000;
$linearGradient:linear-gradient(to right, #F08C02,#FFC737);


// Media queries
$minimobile: "(max-width: 345px)";
$smallmobile: "(max-width: 359px)";
$mediummobile: "(max-width: 390px)";
$mobile: "(max-width: 768px)";
$mobileP: "(max-width: 479px)"; //Mobile - Portrait
$mobileL: "(min-width: 480px) and (max-width: 767px)"; //Mobile - Landscape
$nonMob: "(min-width: 768px)";
$nonPortable: "(min-width: 992px)";
$tablet: "(min-width: 768px) and (max-width: 991px)";
$smallDesktop: "(min-width: 992px) and (max-width: 1199px)";
$desktop: "(min-width: 1200px)";
$largeDesktop: "(min-width: 1366px)";
$mobTab: "(max-width: 991px)";
$tabDesk: "(min-width: 768px) and (max-width: 1199px)";
$nonDesk: "(max-width: 1199px)";
$ipad: "(device-width: 768px) and (device-height: 1024px)";