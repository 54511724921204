@import "../../sass/style.scss";

.CookiePolicyPopup {
    position: fixed;
    bottom: 10px;
    width: 35%;
    height: auto;
    background-color: $white;
    z-index: 3;
    border-radius: 10px;
    left: 10px;

    @media #{$tabDesk} {
        width: 90%;
        margin: auto;

    }

    @media #{$mobile} {
        width: 95%;
        margin: auto;
    }
}

.CookiePolicyParagraph {
    background-color: transparent;
    color: $black;
    display: block;
    text-align: left;
    font-size: 15px;
    padding: 20px 10px;
    font-family: $boldfont;

    a {
        color: $mainColor;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}

.btnPositionCont {
    @include flex($justify-content: space-between);
    padding: 0 10px 10px 10px;

}

.btnPositionContSetting {
    @include flex($justify-content: space-between);
    padding-top: 20px;

}

.secondBtns {
    @include flex($justify-content: space-between);
    gap: 5px;
}

.btnStyle {
    font-size: 15px;
    background: linear-gradient(90deg, rgb(240, 140, 2) 30%, rgb(254, 198, 54) 100%);
    width: fit-content;
    height: 30px;
    border-radius: 10px;
    background-color: $mainColor;
    border: 1px solid $mainColor;
    color: $white;

    &:hover {
        background: #FFFFFF;
        color: #008B40;
    }

    @media #{$mobile} {
        width: 100px;
        font-size: 12px;
    }
}

.firstBtn {
    background: $white;
    color: $black;

    &:hover {
        background: linear-gradient(90deg, rgb(240, 140, 2) 30%, rgb(254, 198, 54) 100%);
        color: $white;
    }
}

.background {
    background: linear-gradient(90deg, rgb(240, 140, 2) 30%, rgb(254, 198, 54) 100%);

}

.policyTabCont {
    width: 70%;
    margin: 10px auto;

    @media #{$mobile} {
        width: 95%;
    }
}

.titlePolicy {
    font-size: 18px;
    font-family: $boldfont;

    @media #{$mobile} {
        font-size: 14px;
    }
}

.paragraphPolicy {
    font-size: 16px;
    font-family: $mediumfont;
    margin: 10px 0;

    @media #{$mobile} {
        font-size: 14px;
    }

}

.popupContent {
    width: 90%;
    margin: auto;
    padding: 20px;
    z-index: 5;
}

.CookieSettingsPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    border-radius: 20px;
    z-index: 5;
}

.titleSetting {
    background: linear-gradient(90deg, rgb(240, 140, 2) 30%, rgb(254, 198, 54) 100%);
    height: 70px;
    line-height: 70px;
    display: block;
    border-radius: 20px;
    text-align: center;
    color: $white;
    font-size: 18px;
}

.CookiePolicySetting {
    background-color: transparent;
    color: $black;
    display: block;
    text-align: left;
    font-size: 18px;

}

.closeBtn {
    position: absolute;
    background: $white;
    border: none;
    width: 20px;
    height: 20px;
    line-height: 5px;
    color: $secondaryColor;
    border-radius: 100%;
    top: 8px;
    right: 10px;
    font-size: 17px;
    cursor: pointer;
}

.inputStyle {
    font-size: 15px;
    font-family: $boldfont;
    color: $white;

}

.cookieOption {
    @include flex($justify-content: space-between);
    padding: 10px 0 10px 0;

}

.allContToggle {
    background-color: $mainColor;
    border-radius: 10px;
    padding: 10px;
}

.toggleSwitch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    cursor: pointer;
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    transition: 0.4s;
    border-radius: 34px;
    height: 24px;
}

.slider.on {
    background-color: #009E60;
}

.slider.off {
    background-color: $white;
}

.slider:before {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: $mainColor;
    left: 4px;
    bottom: 1px;
    transition: 0.4s;
}

.slider.on:before {
    transform: translateX(30px);
}

.slider.off:before {
    transform: translateX(0);
}