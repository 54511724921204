.sidemenu-container {
    background: var(--dark-color);
}
.sidemenu-container.col-12 {
    padding: 0 0 0 0;
}

.pick3-random .dice {
    display: block;
    width: 70px;
    height: 70px;
    background: url('assets/images/dice.png') no-repeat ;
    background-size: contain;
    margin: 20px auto;
    -webkit-animation-duration: .7s;
    animation-delay: 1000ms;
    animation-duration: .7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    animation-name: bounce-dice;
}
.Countdown.loadable-block .overlay {
    background: none;
}
@keyframes bounce-dice {
    0% {
        -webkit-transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-30%);
    }
    100% {
        -webkit-transform: translateY(10%);
    }
}
.game-sidemenu .game-logo {
    padding: 0 25px;
    padding-top: 5%;
    margin-bottom: 20px;
}
.game-sidemenu .draw-countdown {
    background: var(--main-color);
    margin: 30px 0;
    padding: 5px 20px;
    text-align: center;
}
.draw-countdown .loading-container.overlay  .fa {
    color: #fff;
   font-size: 15px;
}
.game-logo {
    padding: 0 20px;
    text-align: center;
}
.game-logo.sport-logo img {
    width: 70px;
}
.game-logo img {
    width: 150px;
    display: block;
    margin: auto;
}
.draw-countdown .Countdown-col-element {
    display:inline-block;
}
.game-container > .row {
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
}
.game-sidemenu .draw-countdown p {
    color: #ffffff;
    text-transform: uppercase;
    font-family: "SF semibold";
    font-size: 15px;
    text-align: center;
    margin-bottom: 0;
}
.game-sidemenu .draw-countdown .Countdown-col-element strong {
    color: #ffffff;
    font-family: "SF semibold";
    font-size: 40px;
    display: block;
    text-align: center;
    margin-bottom: 0;
}
.game-sidemenu .draw-countdown .Countdown-col:first-of-type,
.game-sidemenu .draw-countdown .Countdown-col.hour,
.game-sidemenu .draw-countdown .Countdown-col.days {
    display: none;
}
.game-sidemenu .draw-countdown .Countdown-col-element {
    margin: 0 5px;
}
.game-sidemenu .draw-countdown .Countdown-col-element span {
    color: #ffffff;
    text-transform: uppercase;
    font-family: "SF text";
    font-size: 13px;
    text-align: center;
    display: block;
    margin-top: -10px;
}
.game-sidemenu ul {
    list-style: none;
    padding: 0;
    margin: 0 0 30px 0;
}
.game-sidemenu ul li:after {
    font-size: 30px;
    right: 20px;
    top: -2px;
    position: absolute;
    color: var(--dark-color);
}
.game-sidemenu li {
    display: block;
    height: 60px;
    line-height: 60px;
    position: relative;
    padding: 0 20px;
}
.game-sidemenu li.active,
.game-sidemenu li:hover {
    background: #ffffff;

}
.game-sidemenu li.active a,
.game-sidemenu li:hover a {
    color: var(--dark-color)
}

.game-sidemenu li a {
    color: #ffffff;
    text-transform: uppercase;
    font-family: "SF UI Medium";
    font-size: 20px;
    display: block;
    height: 100%;
    width: 100%;
}
.game-sidemenu li a:hover {
    text-decoration: none;
}
.game-loggedout {
    padding: 0 40px;
    overflow: hidden;
}
.game-loggedout h3,
.game .game-loggedout h2,
.game-loggedout p {
    color: var(--main-color);
    text-transform: uppercase;
    font-family: SF semibold;
    text-align: left;
}
.game-loggedout .login-game {
    width: fit-content;
    font-size: 40px;
    line-height: 45px;
    font-family: SF semibold;
    text-transform: uppercase;
    display: block;
    color: var(--main-color);
    margin: 40px auto 0;
    text-align: center;
    text-decoration: underline;
}
.game {
    padding: 40px 20px;
    position: relative;
}
.game h2,
.game h4 {
    color: var(--main-color);
    text-transform: uppercase;
    text-align: center;
}
.pick3-game {
    overflow: hidden;
    width: 65%;
    margin: 30px auto 0;
}
.pick3-game .pick3-fields {
     margin-top: 60px;
}
.pick3-game .game-fields label {
    color: var(--main-color)
}
/* .type-number{
    width: 32%;
    margin-right: 1%;
    float: left;
    box-shadow: -4px 3px 10px -1px rgba(0, 0, 0, 0.41);
    padding: 0 10px;
    font-size: 20px;
    text-align: center;
    font-family: "SF UI Medium";
    color: var(--dark-color);
    border: none;
    border-radius: 10px;
    height: 40px;
    line-height: 40px;
} */
.pick3-game .game-fields>div  input[type=number],
.pick3-game .form-control {
    background: #ffffff;
    height: 60px;
    line-height: 60px;
    color: var(--dark-color);
    font-size: 30px !important;
    text-align: center;
    width: 100px;
    margin: auto;
    padding: 0 10px !important;
    font-family: "SF bold";
    border-radius: 15px;

    margin: 0 5px;
}
.pick3-game .form-control {
    margin: auto;
}
.pick3-fields .react-numeric-input {
    width: 200px;
    margin: auto;
}
.pick3-fields  .game-fields {
    margin-bottom: 30px;
}
.pick3-fields  {
    text-align: center;
}
.pick3-random {
    width: 100%;
    text-align: center;
    margin: 20px 0;
}
.pick3-random a{
    display: contents;
    width: fit-content;
    object-fit: contain;
    justify-content:center;
    align-items: center;
}
.pick3-random span {
    text-transform: uppercase;
    font-family: "SF text";
    font-size: 15px;
    color: var(--main-color);
}
/* .pick3-random .dice {
    display: block;
    width: 70px;
    height: 70px;
    background: url("assets/images/dice.png") no-repeat;
    background-size: contain;
    margin: 20px auto;
    -webkit-animation-duration: .7s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    animation-duration: .7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-name: bounce-dice;
    animation-name: bounce-dice;
} */
.play-methods {
    width: 50%;
    margin: auto;
    padding: 50px 0;
    display: block;
}
.play-methods>a {
    display: block;
    width: 100%;
    font-family: "SF semibold";
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px;
    margin-bottom: 30px;
    color: #ffffff;
    background: var(--main-color);
    border-color: var(--main-color);
}
.play-methods>a:focus,
.play-methods>a:hover {
    text-decoration: none;
    border: 1px solid var(--main-color);
    background: none;
    color: var(--main-color)
}
.pick3 {
    display: none;
}
.pick3 .back {
    display: inline-block;
    color: var(--main-color);
    position: absolute;
    left: 10px;
    top: 20px;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 18px;
    font-family: "SF semibold";
}
.insta-how ul {
    list-style: none;
    padding: 0;
    margin-top: 50px;
}
.insta-how li {
    display: block;
    margin-bottom: 30px;
    overflow: hidden;
    text-align: left;
}
.insta-how li i {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 1px solid var(--main-color);
    border-radius: 100%;
    color: var(--main-color);
    font-family: SF bold;
    font-size: 25px;
    margin-right: 30px;
    text-align: center;
    font-style: normal;
    float: left;
}
.insta-how li span {
    display: block;
    width: calc(100% - 70px);
    float: left;
    font-family: "SF text";
    color: var(--main-color);
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 5px;
}
.play-methods.hidden {
    display: none;
}
.pick3.visible {
    z-index: 100;
    display: block;
}
.purchases-table .table-container {
    color: #fff;
    margin-top: 20px;
}
.purchases-table table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
}
.purchases-table table thead th {
    color: var(--main-color);
    text-transform: uppercase;
    font-size: 14px;
    font-family: SF text;
    text-align: center;
    position: relative;
    padding-bottom: 5px;
    border: none;
}
.purchases-table table tbody tr {
    background: var(--main-color);
    border: none;
}
.purchases-table table tbody tr td {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    font-family: "SF semibold";
    text-align: center;
    padding: 5px 0;
    border: none;
}
.purchases-table table tbody tr td:last-of-type {
    border-radius: 0 10px 10px 0;
}
.purchases-table table tbody tr td:first-of-type {
    border-radius: 10px 0px 0px 10px;
}
.filter-container {
    position: relative;
    display: block;
}
.purchases-table input.form-control,
.purchases-table input.form-control:focus {
    color: var(--main-color);
    background: none;
    outline: none;
    border-color: var(--main-color);
}
.filter-container .close {
    position: absolute;
    top: -3px;
    right: 5px;
    color: var(--main-color);
}
.table-container > div {
    color: var(--main-color)
}
.purchases-table .form-control {
    color: var(--dark-color);
    border: 1px solid var(--main-color);
}
.purchases-table .record-count {
    display: none;
}
.purchases-table .pagination {
    color: var(--main-color);
    float: right;
    margin-top: 40px;
}
.pagination-sm.pull-right {
    display: none
}
.pagination .btn-numbered-page.active {
    background: var(--main-color);
    color: #ffffff;
}
.pagination .btn-numbered-page {
    display: inline-block;
    padding: 0 2px;
    cursor: pointer;
}
.scratch .logo-text span {
    color: #ffffff;
    font-family: "SF semibold";
    font-size: 20px;
    text-transform: uppercase;
}
.game-sidemenu .available-balance {
    overflow: hidden;
    margin: 0 0 15px;
    padding: 15px;
    background: var(--main-color)
}
.game-sidemenu .available-balance h3 {
    color: #ffffff;
    font-family: SF bold;
    text-transform: uppercase;
    display: block;
    width: 100%;
    font-size: 20px;
    margin-top: 10px;
    text-align: center;
}
.game-sidemenu .available-balance span {
    color: #fff;
    font-family: SF text;
    text-transform: uppercase;
    display: block;
    text-align: center;
    width: 100%;
}
.game-sidemenu .available-tickets {
    background: var(--main-color);
    color: #ffffff;
    padding: 10px 20px;
    margin: 0;
}
.available-tickets {
    position: relative;
}
.available-tickets p {
    width: 80%;
    display: inline-block;
    padding-right: 20px;
    margin: 0;
}
.available-tickets:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    background: url('./assets/images/coin.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.available-tickets label,
.available-tickets span {
    color: #fff;
    font-family: SF text;
    text-transform: uppercase;
    display: block;
    text-align: left;
    width: 100%;
}
.available-tickets span {
    font-size: 16px;
}
.game.scratch {
     position: relative;
     padding: 30px 0 50px;
     z-index: 100;
     overflow: hidden;
 }
.game-container .ae-container {
    position: relative;
}
.game-container .ae-container:before {
    content: '';
    position: absolute;
    left: -40px;
    background: url('assets/images/ae-bg-2.png') no-repeat;
    width: 40%;
    height: 60%;
    z-index: 1;
    background-size: contain;
    bottom: 0;
}
.game-container .ae-container:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('assets/images/ae-bg-1.png') no-repeat;
    height: 350px;
    width: 100%;
    background-position: top;
    z-index: 0;
}
.game.scratch .game-subcontainer {
    width: 100%;
    background-repeat: repeat;
    background-position: bottom;
    height: 100%;
    z-index: 100;
    position: relative;
    overflow: hidden;
}
.scratch-grid {
    justify-content: center;
    display: grid;
    width: 550px;
    float: left;
    overflow: hidden;
    margin-top: 40px
}
.scratch-table {
    margin: auto;
}
.scratch-table .scratch-table-row {
    overflow: hidden;
}
.buy-ticket-container.new-design {
    width: calc(100% - 560px);
}
.buy-ticket-container {
    width: 35%;
    float: left;
    margin-top: 70px;
}
.game-subcontainer h3 {
    font-size: 25px;
    width: 100%;
    color: var(--main-color);
    text-align: center;
}
.scratch-table-container {
    width: 320px;
    border: 1px solid #c83174;
    border-radius: 10px;
    padding: 5px 5px 40px 5px;
    margin-left: 40px;
    background-color: #ffffffa1;
    cursor: url('assets/images/coin-1.png'), auto;
}
.scratch-table-container.updated-design {
    width: 500px;
    border-radius: 10px;
    padding: 5px 5px 40px 5px;
    position: relative;
    border: 1px solid #c83174;
}


.updated-design .scratch-table .scratch-table-row {
    padding: 10px 0;
}
.fruit .scratch-table-container.updated-design {
    background: url('assets/images/table-with-fuzzy-background.png') no-repeat;
    background-size: cover ;
    /*padding-top: 129px;*/
}
.sport .scratch-table-container.updated-design {
    background: url('assets/images/sport-scratch-bg.png') no-repeat;
    background-size: 139% 100%;
    /*padding-top: 129px;*/
}
.scratch-table-container.updated-design p {
    color: #fff;
    font-family: 'SF text';
    font-size: 14px;
    margin: 5px 0 0 35px;;
}
.fruit .scratch-table-container.updated-design .scratch-ananas {
    width: 185px;
    position: absolute;
    bottom: 55px;
    right: -10px;
    z-index: 100;
}
.bingo .scratch-table-container.updated-design .bingo-ticket-price {
    position: absolute;
    bottom: 100px;
    right: 40px;
    width: 70px;
    z-index: 100;
}
.sport .scratch-table-container.updated-design .football {
    position: absolute;
    bottom: 60px;
    right: 20px;
    width: 30px;
    z-index: 100;
}
.scratch-table-container.updated-design .price-tag {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
}
.scratch-table-container.updated-design .scratch-logo {
    margin: 10px 30px 0;
    width: 200px;
}
.scratch-table-container.updated-design canvas {
    width: 300px;
    height: 205px;
    border: 10px solid #5d5d5d;
    left: 30px;
    bottom: -8px;
}
.fruit .scratch-table-container.updated-design .scratch-table {
    background: #f7a49d;
}
.scratch-table-container.updated-design .scratch-table {
    width: 300px;
    height: 205px;
    left: 30px;
    bottom: -8px;
    position: absolute;
}
.scratch-table-row .flippy-back {
    border: 2px solid var(--main-color);
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 10px;
}
.scratch-table .scratch-table-row .scratch-table-items .flippy-front span {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    background: rgb(118,187,150);
    background: radial-gradient(circle, rgba(118,187,150,1) 0%, rgba(87,156,119,1) 100%);
    display: block;
    border-radius: 10px;
}
.scratch-table-row .flippy-back.sport-back {
    border-color: #b0b0b0;
    background: #b0b0b0;
}
.sport-value {
    display: block;
    height: 63px;
    line-height: 64px;
    font-family: "SF bold";
    color: #ffffff;
    font-size: 14px;
    max-width: 90px;
    margin: auto;
    text-shadow: 3px 3px 4px rgb(90, 87, 87);
}
.sport-game-container {
    background: url('assets/images/sport-bg.png') no-repeat;
    background-size: contain;
    background-position: center;
}
.scratch-table .scratch-table-row .scratch-table-items {
    width: 100px;
    float: left;
    text-align: center;
}
.african .scratch-table .scratch-table-row .scratch-table-items {
    background: #eea968;
}
.sport .scratch-table .scratch-table-row .scratch-table-items {
    background: #009840;
}

.bingo .scratch-table .scratch-table-row .scratch-table-items {
    background: #ed8e84;
}
/*.fruit .scratch-table-container {*/
/*    background: url('assets/images/af-card-bg.png') #ffffffa1 no-repeat;*/
/*    background-size: 150%;*/
/*    background-position: bottom;*/
/*}*/
.bingo .scratch-table-container {
    background-image: url('assets/images/bingo-bg.png');
    background-repeat: no-repeat;

}
.scratch-table .scratch-table-row .scratch-table-items img {
    height: 52px;
    margin: 5px auto;
    width: auto;
    max-width: 100%;
}

.img-african{
    height: 90px !important;
    margin: 10px auto !important;
}

.bingo-card {
    position: relative;
}
.bingo-card:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 10px;
    background-repeat: no-repeat;
    width: 70px;
    height: 70px;
    background-size: cover;
}
.color-A {
    background-color: #468e3b;
}
.color-A:after {
    background-image: url('assets/images/bingo/a.png')
}
.color-B {
    background-color: #e18031;
}
.color-B:after {
    background-image: url('assets/images/bingo/b.png')
}
.color-C {
    background-color: #a0639b;
}
.color-C:after {
    background-image: url('assets/images/bingo/c.png')
}
.color-D {
    background-color: #f8b031;
}
.color-D:after {
    background-image: url('assets/images/bingo/d.png')
}
.color-E {
    background-color: #7480b4;
}
.color-E:after {
    background-image: url('assets/images/bingo/e.png')
}
.color-F {
    background-color: #e84b63;
}
.color-F:after {
    background-image: url('assets/images/bingo/f.png')
}
.color-G {
    background-color: #425e82;
}
.color-G:after {
    background-image: url('assets/images/bingo/g.png')
}
.color-H {
    background-color: #ea638c;
}
.color-H:after {
    background-image: url('assets/images/bingo/h.png')
}
.color-O {
    background-color: #92c147;
}
.color-O:after {
    background-image: url('assets/images/bingo/o.png')
}
.color-M {
    background-color: #f8b031;
}
.color-M:after {
    background-image: url('assets/images/bingo/m.png')
}
.color-S {
    background-color: #2b7236;
}
.color-S:after {
    background-image: url('assets/images/bingo/s.png')
}
.color-Z {
    background-color: #b9aca7;
}
.color-Z:after {
    background-image: url('assets/images/bingo/z.png')
}

.pick3-game-container {
    background: url('assets/images/pick3-bg.png') no-repeat;
    background-position: center;
    background-size: cover;
}
.scratch-table .flippy-card {
    padding: 0;
    box-shadow: none;
}
.highlight .winning .flippy-back {
    background: rgba(252, 111, 12, 0.65);
}
.game .scratch-all {
    display: block;
    width: 150px;
    margin: auto;
    border: 1px solid var(--main-color);
    line-height: 35px;
    margin-top: 40px;
}
.scratch-tickets .main-btn,
.play-again.main-btn {
    background: var(--main-color);
    color: #ffffff;
    display: block;
    width: 180px;
    margin: 0 auto 20px;
    font-family: "SF semibold";
    margin-top: 40px;
}
.game-subcontainer .ticket-price {
    margin: 0 auto 0;
    width: 205px;
}
.game-subcontainer .ticket-price label {
    color: var(--main-color);
    text-transform: uppercase;
    font-size: 20px;
    font-family: "SF text";
    display: block;
    margin-bottom: 5px;
    text-align: center;
}
.game-subcontainer .ticket-price span {
    color: var(--main-color);
    text-transform: uppercase;
    padding: 10px 20px;
    font-size: 25px;
    font-family: "SF semibold";
    display: block;
    text-align: center;
    border: 2px solid  var(--main-color);;
    border-radius: 10px;
}

.game .winnings span {
    display: block;
    color: var(--main-color);
    text-transform: uppercase;
    font-size: 20px;
    font-family: SF semibold;
    margin-bottom: 0;
    text-align: center;
}
.game .winnings p {
    color: var(--main-color);
    text-transform: uppercase;
    font-size: 50px;
    font-family: SF semibold;
    line-height: 40px;
    margin: 0;
    text-align: center;
}
.nomore-tickets {
    color: var(--dark-color);
    text-align: center;
    font-size: 25px;
    text-transform: uppercase;
    font-family: "SF bold";
    width: 100%;
    display: block;
    margin-top: 50px;
}

.scartchhow h3 {
    width: 70%;
    margin: 0 auto 50px;
    text-align: center;
    color: var(--main-color);
    font-family: SF bold;
    font-size: 20px;
    text-transform: uppercase;
}
.scratch-how .scratch-howtowin {
    padding: 0 50px 50px;
}
.scratch-how .scratch-howtowin ul {
    text-align: center;
}
.scratch-how .scratch-howtowin ul li {
    display: inline-block;
    text-align: center;
    min-width: 300px;
    width: 35%;
}
.scratch-tickets {
    text-align: center;
}
.scratch-how .scratch-howtowin ul li:nth-child(odd) {
    padding-right: 25px;
    clear: left;
}
.scratch-how .scratch-howtowin ul li img {
    width: 65px;
    display: inline-block;
}
.play-method img {
    max-width: 200px;
}
.loading-container.overlay {
    text-align: center;
}
.loading-container.overlay .fa {
    font-size: 30px;
}
/*Spin and win game*/
.sw-container {
    position: relative;
}
.sw-container:before {
    content: '';
    position: absolute;
    top: 45px;
    right: -150px;
    width: 300px;
    height: 300px;
    background: url('assets/images/spin-win-bg.png') no-repeat;
    background-size: contain;
}
.spin-win {
    position: relative;
    margin-top: 40px;
    overflow: hidden;
    width: 60%;
    float: left;
}
.spin-win .spin.disabled {
    opacity: 0.5;
}
.spin-win .spin {
    display: block;
    background: #ffffff;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    color: #fa6d09 !important;
    text-transform: uppercase;
    font-family: "SF semibold";
    text-align: center;
    font-size: 20px;
    width: 230px;
    margin: 5px auto 0;
    text-decoration: none !important;
}
.spin-win .spin.disable {
    visibility: hidden;
}
.wheel-container {
    width: 400px;
    margin: 0 auto 20px;
}
.wheel-container .weel-circle {
    width: 400px;
    height: 400px;
    position: relative;
    background: url('assets/images/weel-circle.png') no-repeat;
    background-size: contain;
    margin: auto;
}
.wheel-container .weel-circle img  {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
    width: 96%;
    -webkit-transition: 1s ease-in ;
    -moz-transition: 1s ease-in ;
    -ms-transition: 1s ease-in ;
    -o-transition: 1s ease-in ;
    transition: 1s ease-in ;
}
.wheel-container .weel-circle img.STW1 {
    animation: diamond-keyframes  ease-out 3.5s;
    transform: rotate(160deg);
}
.wheel-container .weel-circle img.STW1.extra {
    animation: diamond-extra-keyframes  ease-out 3.5s;
    transform: rotate(161deg);
}
@keyframes diamond-extra-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(161deg);
    }

}
@keyframes diamond-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(160deg);
    }

}
.wheel-container .weel-circle img.STW2 {
    animation: dollar-keyframes  ease-out 3.5s;
    transform: rotate(290deg);
}
@keyframes dollar-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(290deg);
    }

}
.wheel-container .weel-circle img.STW2.extra {
    animation: dollar-extra-keyframes  ease-out 3.5s;
    transform: rotate(291deg);
}
@keyframes dollar-extra-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(291deg);
    }

}
.wheel-container .weel-circle img.STW4 {
    animation: loosing-keyframes  ease-out 3.5s;
    transform: rotate(340deg);
}
@keyframes loosing-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(340deg);
    }

}
.wheel-container .weel-circle img.STW4.extra {
    animation: loosing-extra-keyframes  ease-out 3.5s;
    transform: rotate(341deg);
}
@keyframes loosing-extra-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(341deg);
    }

}
.wheel-container .weel-circle img.STW3 {
    animation: wine-keyframes  ease-out 3.5s;
    transform: rotate(250deg);
}
@keyframes wine-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(250deg);
    }

}
.wheel-container .weel-circle img.STW3.extra {
    animation: wine-extra-keyframes  ease-out 3.5s;
    transform: rotate(251deg);
}
@keyframes wine-extra-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(251deg);
    }

}
.wheel-container .weel-circle img.reset {
    transform: rotate(0deg);
}
.wheel-container .weel-circle img.STW5 {
    animation: cherry-keyframes  ease-out 3.5s;
    transform: rotate(70deg);
}
@keyframes cherry-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(70deg);
    }

}
.wheel-container .weel-circle img.STW5.extra {
    animation: cherry-extra-keyframes  ease-out 3.5s;
    transform: rotate(71deg);
}
@keyframes cherry-extra-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(71deg);
    }

}
.wheel-container .weel-circle img.STW6 {
    animation: dice-keyframes  ease-out 3.5s;
    transform: rotate(120deg);
}
@keyframes dice-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(120deg);
    }

}
.wheel-container .weel-circle img.STW6.extra {
    animation: dice-extra-keyframes  ease-out 3.5s;
    transform: rotate(125deg);
}
@keyframes dice-extra {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(125deg);
    }

}
.wheel-container .weel-circle img.losing1 {
    animation: losing1-keyframes  ease-out 3.5s;
    transform: rotate(115deg);
}
@keyframes losing1-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(115deg);
    }

}
.wheel-container .weel-circle img.losing1.extra {
    animation: losing1-extra-keyframes  ease-out 3.5s;
    transform: rotate(116deg);
}
@keyframes losing1-extra-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(116deg);
    }

}
.wheel-container .weel-circle img.losing2 {
    animation: losing2-keyframes  ease-out 3.5s;
    transform: rotate(200deg);
}
@keyframes losing2-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(200deg);
    }

}
.wheel-container .weel-circle img.losing2.extra {
    animation: losing2-extra-keyframes  ease-out 3.5s;
    transform: rotate(201deg);
}
@keyframes losing2-extra-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(201deg);
    }

}
.wheel-container .weel-circle img.losing3 {
    animation: losing3-keyframes  ease-out 3.5s;
    transform: rotate(20deg)
}
@keyframes losing3-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(20deg);
    }

}
.wheel-container .weel-circle img.losing3.extra {
    animation: losing3-extra-keyframes  ease-out 3.5s;
    transform: rotate(21deg);
}
@keyframes losing3-extra-keyframes {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(21deg);
    }

}
.wheel-container .weel-circle img.STW6.extra {
    animation: dice-extra-keyframes  ease-out 3.5s;
    transform: rotate(125deg);
}
@keyframes dice-extra {
    0% {
        transform: rotate(2080deg);
    }
    100% {
        transform: rotate(125deg);
    }

}
.playspin .clicked {
    animation: show-winnings ease-in 5s;
    position: relative;
    margin:  0;
    width: auto;
    text-align: center;
    padding: 5px 20px 10px 20px;
    color: #ffffff;
}

@keyframes show-winnings {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}
.wheel-container .weel-circle:before {
    content: '';
    top: 50% ;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    position: absolute;
    background: url('assets/images/weel-picker.png') no-repeat;
    background-size: contain;
    width: 57px;
    height: 100px;
    z-index: 10;
}
.wheel-container .weel-base {
    height: 150px;
    width: 200px;
    background: url('assets/images/wheel-base.png') no-repeat;
    background-size: contain;
    margin: -20px auto 0;
}
.scratch-how .game-not-available,
.scratch.spinwin .game-not-available {
    display: block;
    color: #fa6d09;
    text-align: center;
    text-transform: uppercase;
    font-family: "SF UI Medium";
    font-size: 30px;
    margin-top: 50px;
}
.sport-how-win {
    margin-top: 80px;
}
.sport-how-win p {
    display: block;
    color: var(--main-color);
    text-align: center;
    text-transform: uppercase;
    font-family: "SF UI Medium";
    font-size: 30px;
    margin-top: 50px;
    font-style: italic;
}
.game-sidemenu ul li:after {
    font-size: 30px;
    right: 20px;
    top: -2px;
    position: absolute;
    color: var(--dark-color);
}
.game-sidemenu.pick3-sidemenu  ul.play li:first-of-type {
    background: #ffffff;
}
.game-sidemenu.pick3-sidemenu  ul.play li:first-of-type:after {
    content: '>';
}
.game-sidemenu.pick3-sidemenu  ul.howtoplay li:nth-child(2) {
    background: #ffffff;
}
.game-sidemenu.pick3-sidemenu  ul.howtoplay li:nth-child(2):after {
    content: '>';
}
.game-sidemenu.pick3-sidemenu  ul.howtowin li:nth-child(3) {
    background: #ffffff;
}
.game-sidemenu.pick3-sidemenu  ul.howtowin li:nth-child(3):after {
    content: '>';
}
.game-sidemenu.pick3-sidemenu  ul.purchases li:nth-child(4) {
    background: #ffffff;
}
.game-sidemenu.pick3-sidemenu  ul.purchases li:nth-child(4):after {
    content: '>';
}
.game-sidemenu.pick3-sidemenu  ul.winnings li:nth-child(5) {
    background: #ffffff;
}
.game-sidemenu.pick3-sidemenu  ul.winnings li:nth-child(5):after {
    content: '>';
}
.game-sidemenu.pick3-sidemenu  ul.winners li:last-of-type {
    background: #ffffff;
}
.game-sidemenu.pick3-sidemenu  ul.winners li:last-of-type:after {
    content: '>';
}
.game-sidemenu.scratch  ul.winnings li:nth-child(5) a,
.game-sidemenu.scratch  ul.purchases li:nth-child(4) a,
.game-sidemenu.scratch ul.howtoplay li:nth-child(2) a,
.game-sidemenu.scratch ul.howtowin li:nth-child(3) a,
.game-sidemenu.scratch ul.play li:first-of-type a,
.game-sidemenu.scratch ul.howtowin.scratch-out li:nth-child(3) a,
.game-sidemenu.pick3-sidemenu  ul.play li:first-of-type a,
.game-sidemenu.pick3-sidemenu  ul.howtoplay li:nth-child(2) a,
.game-sidemenu.pick3-sidemenu  ul.howtowin li:nth-child(3) a,
.game-sidemenu.pick3-sidemenu  ul.purchases li:nth-child(4) a,
.game-sidemenu.pick3-sidemenu  ul.winnings li:nth-child(5) a,
.game-sidemenu.pick3-sidemenu  ul.winners li:last-of-type a {
    color: var(--dark-color);
}
.game-sidemenu.scratch  ul.play li:first-of-type {
    background: #ffffff;
}
.game-sidemenu.scratch  ul.play li:first-of-type:after {
    content: '>';
}
.game-sidemenu.scratch  ul.howtoplay li:nth-child(2) {
    background: #ffffff;
}
.game-sidemenu.scratch  ul.howtoplay li:nth-child(2):after {
    content: '>';
}
.game-sidemenu.scratch  ul.howtowin li:nth-child(3) {
    background: #ffffff;
}
.game-sidemenu.scratch  ul.howtowin li:nth-child(3):after {
    content: '>';
}
.game-sidemenu.scratch  ul.purchases li:nth-child(4) {
    background: #ffffff;
}
.game-sidemenu.scratch  ul.purchases li:nth-child(4):after {
    content: '>';
}
.game-sidemenu.scratch  ul.winnings li:nth-child(5) {
    background: #ffffff;
}
.game-sidemenu.scratch  ul.winnings li:nth-child(5):after {
    content: '>';
}
.game-sidemenu.scratch  ul.winners li:last-of-type {
    background: #ffffff;
}
.game-sidemenu.scratch  ul.winners li:last-of-type:after {
    content: '>';
}
.how-win-spin {
    margin-top: 70px;
}
.how-win-spin .how-win-details {
    margin-bottom: 20px;
    text-align: center;
}
.how-win-details .icon-container img {
    width: 100%;
    display: inline-block;
}
.how-win-details .icon-container {
    display: inline-block;
    margin-right: 20px;
    width: 100px;
    text-align: center;
}
.how-win-details span {
    color: var(--main-color);
    font-family: "SF UI Medium";
    font-size: 25px;
    text-transform: uppercase;
}

@media only screen and (max-width: 992px) {
    .game-sidemenu .game-logo {
        
        display: none;
    }
    .sidemenu-container {
        /*margin-top: 30px;*/
        height: 100px;
    }
    .side-info-container .fa {
        left: 0;
        color: #ffffff;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        position: absolute;
        font-size: 25px;
    }
    .side-info-container .fa:hover,
    .side-info-container .fa:focus {
        text-decoration: none;
    }

    .game-sidemenu .available-balance span,
    .game-sidemenu .available-balance h3 {
        width: 100%;
        text-align: left;
        font-size: 12px;
    }

    .game-sidemenu .available-balance h3 {
        font-size: 14px;
    }
    .game-sidemenu .available-balance span {
        background: none;
        padding: 0;
    }
    .game-sidemenu .draw-countdown p {
        font-size: 12px;
        margin: 0;
    }
    .game-sidemenu .draw-countdown {
        display: inline-block;
        width: 50%;
        padding: 0 0 0 30px;
        padding: 0 0 0 30px;
        margin: 0;
        background: none;
        vertical-align: middle;
    }
    .game-sidemenu .draw-countdown .Countdown-col-element strong {
        font-size: 25px;
    }
    .game-sidemenu {
    
        position: relative;
    }
    .game-sidemenu ul {
        height: 0;
        overflow: hidden;
        position: absolute;
        transition-property: all;
        transition-duration: .5s;
        background-color: var(--dark-color);
        top: 80px;
        left: 0;
        z-index: 10000;
        right: 0;
    }
    .game-sidemenu.open ul {
        height: auto;
        transition-property: height;
        transition-duration: .5s;
    }
    .game-sidemenu .draw-countdown .minutes-label {
        font-size: 11px;
    }
    .game-sidemenu .draw-countdown .minutes {
        font-size: 25px;
    }
    .game {
        padding: 40px 5px;
    }
    .game h4 {
        font-size: 18px;
    }
    .insta-how h3 {
        margin: 0 auto;
        font-size: 15px;
        width: 85%;
    }
    .insta-how li {
        margin-bottom: 20px;
    }
    .insta-how li span {
        font-size: 16px;
    }
    .play-method img {
        width: 40%;
        margin: auto;
        display: block;
    }
    .purchases-table {
        padding: 0;
    }
    .purchases-table .table-headings span {
        font-size: 12px;
    }
    .filterable-table-container .filter-container{
        margin-bottom: 15px;
    }
    .purchases-table nav li a {
        padding: 10px;
    }
    .pick3 .back {
        position: relative;
        top: auto;
        display: block;
    }
    .pick3-fields {
        width: 100%;
        float: none;
        padding: 0;
    }
    .pick3-random {
        width: 100%;
        margin-top: 30px;
    }
    .side-info-container {
      
        height: 100%;
        position: relative;
    }
    .side-info-container .fa.fa-align-justify {
        left: 15px;
        display: block !important;
    }
    .game-sidemenu .side-info-container .available-balance {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
        padding-left: 55px;
        padding-right: 0;
        padding-top: 5px;
        overflow: hidden;
        background: none;
    }
    .game-sidemenu .available-tickets {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        background: none;
    }
    .available-tickets:after {
        display: none;
    }
    .available-tickets label {
        display: none;
    }
    .available-tickets span {
        text-align: right;
    }
    .available-tickets p {
        width: 100%;
    }
    .game-sidemenu .game-logo {
        display: none;
    }
    .game-sidemenu .draw-countdown {
        display: inline-block;
        width: 50%;
        padding: 0 0 0 30px;
        margin: 0;
        background: none;
        vertical-align: middle;
        float: right;
        position: relative;
    }
    .game-sidemenu .draw-countdown p {
        font-size: 12px;
        margin: 0;
    }
    .game-sidemenu .draw-countdown p {
        font-size: 12px;
        margin: 0;
    }
    .Countdown {
        text-align: center;
    }
    .sidemenu-container.col-12 {
        padding-top: 20px;
    }
    .Countdown.loadable-block .overlay .fa {
        margin-top: 20px;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
    }
    .pick3-game {
        width: 100%;
    }
    .spin-win {
        text-align: center;
        width: 100%;
    }
    .wheel-container {
        width: 100%;
    }
    .buy-ticket-container,
    .buy-ticket-container.new-design {
        width: 100%;
    }
    .scratch-grid {
        width: 100%;
        float: none;
        display: block;
    }
    .scratch-overall .only-mobile .game-results-action {
        margin-bottom: 30px;
    }
    .scratch-table-container {
        margin: auto;
    }
    .game-container .ae-container:before  {
        width: 100%;
        height: 332px;
    }
    .game-container .ae-container:after {
        display: none;
    }
    .sport-game-container {
        background: none;
    }
    .wheel-container .weel-circle {
        width: 300px;
        height: 300px;
    }
    .scratch-table-container.updated-design {
        width: 100%;
    }
    .scratch-table-container.updated-design .scratch-ananas {
        display: none;
    }
    .sport .scratch-table-container.updated-design .football {
        display: none;
    }
    .bingo .scratch-table-container.updated-design .bingo-ticket-price {
        bottom: 0;
    }

}
