@import "../../sass/style.scss";

.footer {
  background-color: #efefef;
  padding: 20px 20px 5px 20px;
  @media #{$mobile} {
    padding: 20px 5px 5px 5px;
  }
  @media #{$tablet} {
    padding: 20px 10px 5px 10px;
  }
}

.operatorMediaContainer {
  @include flex($justify_content: space-between);
  margin-bottom: 30px;
  @media #{$mobile} {
    flex-direction: column;
    margin-bottom: 20px;
  }
}


.operatorsImages {
  width: 40%;
  @media #{$mobile} {
    @include flex($justify_content:space-between);
    width: 100%;
    margin-bottom: 10px;
  }
}

.logoJounos {
  margin: 10px;
  width: 120px;
  @media #{$mobile} {
    width: 100px;
  }
  @media #{$minimobile} {
    width: 70px;
  }
}

.logo {
  margin: 10px;
  width: 65px;
  @media #{$tablet} {
    width: 50px;
  }
  @media #{$mobile} {
    width: 40px;
  }
  @media #{$minimobile} {
    width: 30px;
  }
}

.mediaCircles {
  display: inline-block;
  position: absolute;
  right: 10px;
  @media #{$mobile} {
    width: fit-content;
    position: relative;
    display: block;
    margin: 20px auto 0;
    right: 0;
  }
}

.circleMedia {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: $secondaryColor;
  margin: 5px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  @media #{$mobile} {
    width: 30px;
    height: 30px;
  }
}

.icon {
  color: $white;
  font-size: 20px;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 100%;
  background-color: $secondaryColor;
  margin: 5px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  @media #{$mobile} {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 17px;
  }
  &:hover {
    color: white;
  }
}

.footerText {
  @include flex($justify_content: space-between);
  @media #{$mobile} {
    flex-direction: column;
    text-align: center;
  }
}

.textStyle {
  color: $secondaryColor;
  font-weight: 400;
  font-family: $regularfont;
  font-size: 12px;
  @media #{$mobile} {
    flex-direction: column;
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
  }
  @media #{$tablet} {
    font-size: 10px;
  }
  @media #{$minimobile} {
    font-size: 7px;
  }
  &:hover {
    text-decoration:underline ;
    color: $secondaryColor;
  }
}

.pagesContainer {
  width: 25%;
  text-align: center;
}
.titlePage {
  display: block;
  text-transform: uppercase;
  margin: 10px 0;
  color: $secondaryColor;
  font-weight: 800;
}

.linktopage {
  display: block;
  text-align: center;
  font-weight: 500;
  color: $secondaryColor;
  font-size: 15px;
  text-transform: uppercase;
  margin: 10px 0;
  &:hover {
    text-decoration: underline;
    color: $secondaryColor;
  }
}
.mediaWrapperBtn {
  display: inline-block;
  @media #{$mobile} {
    display: block;
    width: 100%;
  }
}
.mediaWrapper {
 width: 35%;
 @media #{$mobile} {
  width: 90%;
 }
}
.inputType {
  display: block;
  border: 3px solid #FFC737;
  border-radius: 40px;
  width: 100%;
  padding: 10px 15px;
  outline: none;
  color: $secondaryColor;
  margin: 10px 0;
}

input::placeholder {
  color: $secondaryColor;
  font-size: 14px;
}

.sendBtn {
  display: inline-block;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
  width: 140px;
  border-radius: 50px;
  height: 35px;
  background: linear-gradient(90deg, rgba(240,140,2,1) 30%, rgba(254,198,54,1) 100%);
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  font-size: 14px;
  margin-top: 10px;
  &:hover {
    background: $white;
    color: $secondaryColor;
  }
  @media #{$mobile} {
    display: block;
    margin:20px auto 0;
  }
}